
import Vue from 'vue'

let w = (window as any);
let v = null;
w.culqi = function () {
    //store.commit('setCulqiToken', token) /* CREA ESTE COMMIT EN TU STORE QUE GUARDE EL TOKEN EN EL STATE */
    if (w.Culqi.token) { // �Objeto Token creado exitosamente!
        let result = v.$store.dispatch({
            type: 'paymentgateway/card',
            data: {
                token: w.Culqi.token.id,
                active: w.Culqi.token.active,
                card_number: w.Culqi.token.card_number,
                email: w.Culqi.token.email,
                card_brand: w.Culqi.token.iin.card_brand
            }
        });
        
        //alert('Se ha creado un token:' + token);
        //En esta linea de codigo debemos enviar el "Culqi.token.id"
        //hacia tu servidor con Ajax
    } else { // �Hubo alg�n problema!
        // Mostramos JSON de objeto error en consola
        console.log(w.Culqi.error);
        //v.$toast.error(w.Culqi.error);
        //alert(w.Culqi.error.user_message);
    }
}

export default class CulqiClass {
    constructor(vm: Vue) {
        w.Culqi.publicKey = vm.$store.state.paymentgateway.publicKey;
        v = vm;
    }
    createToken() {
        w.Culqi.options({
            customButton: 'Confirmar',
            style: {
                logo: location.origin + '/img/icons/apple-icon-57x57.png',
                maincolor: '#902370',
                buttontext: '#ffffff',
                maintext: '#4A4A4A',
                desctext: '#4A4A4A'
            }
        });

        w.Culqi.settings({
            title: 'Tendyc',
            currency: 'PEN',
            description: 'Se te realizara un Cobro de S/. 1.00 para verificar esta tarjeta.',
            amount: 1
        });

        w.Culqi.open();
    }
}
